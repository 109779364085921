import React, {useState, useEffect} from "react";
import VideoComponent from "../../../miscellaneous/video-comp/VideoComponent";
import beachVid from "../../../miscellaneous/videos/beachclip.mp4";
import styles from "../Main.module.scss";

/*****************************
 * PageOne
 * exported to Main.js
 ****************************/
export default function PageOne() {


  return (
    <div className={styles.pageonecontainer}>
      <VideoComponent url={beachVid} />
    </div>
  );
}
