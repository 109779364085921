import React from "react";

import logo from "../../../miscellaneous/logos/logo_green_dark.webp";
import styles from "../Main.module.scss";

/*******************************
 * Navigation
 ******************************/
export default function Navigation() {
  return (
    <nav className={styles.navigationcontainer}>
     
        <title>experience Costa Rica</title>
      
      <div className={styles.navigationsubcontainer}>
        <img src={logo}  alt="logo"/>
      </div>
    </nav>
  );
}
