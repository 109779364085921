import React, { useMemo, useState } from "react";
import samarabeach from "../../../miscellaneous/images/samarabeach.webp";
import buttons from "../../../miscellaneous/buttons-style/Button.module.scss";
import { onGetPosition } from "../../../miscellaneous/getLocation/getLocation";
import { dis } from "./interface";
import styles from "../Main.module.scss";

/**********************************
 * PageTwo
 *********************************/
export default function PageTwo() {
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);

  useMemo(() => {
    const la: number =
      dis.degress + dis.minutes / dis.by60 + dis.seconds / dis.by3600;
    const lo: number =
      dis.degress1 + dis.minutes1 / dis.by60 + dis.seconds1 / dis.by3600;
    setLat(la);
    setLong(lo);
  }, []);

  const waze = `https://www.waze.com/ul?ll=${lat},${long}&navigate=yes`;
  // const uber = `uber://?action=setPickup&pickup=my_location&dropoff[latitude]=${lat}&dropoff[longitude]=${long}`;
  // const uberURL = `uber://?action=setPickup&pickup[latitude]=${latitude}&pickup[longitude]=${longitude}`;
  // const uberURL = `uber://?action=setPickup&pickup[latitude]=${pickupLatitude}&pickup[longitude]=${pickupLongitude}&dropoff[latitude]=${dropoffLatitude}&dropoff[longitude]=${dropoffLongitude}`;

  function getPosition() {
    onGetPosition()
      .then((position) => {
        setLat(position.lat);
        setLong(position.lng);
      })
      .catch((error) => {
        console.error("Error getting location:", error.message);
      });
  }

  // console.log(lat);
  // console.log(long);

  return (
    <div className={styles.pagetwocontainer}>
      <div className={styles.pagetwophoto}>
        <img src={samarabeach} alt="samara" />
        <div className={styles.pagetwolayer} />

        <div className={styles.pagetwobutton}>
          <a
            className={buttons.buttonlocation}
            href={waze}
            target="_blank"
            rel="noopener noreferrer"
          >
            find location in waze
          </a>
        </div>
      </div>
      <div className={styles.pagetwoinfo}>
        {" "}
        <button onClick={() => getPosition()}>get location</button>
      </div>
    </div>
  );
}
