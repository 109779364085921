import React, {useState, useEffect} from "react";
import PageOne from "./pageone/PageOne";
import Navigation from "./navigation/Navigation";
import styles from "./Main.module.scss";
import PageTwo from "./pagetwo/PageTwo";

/***************************
 * Main point of entry
 *************************/
export default function Main() {
  const [scrollValue, setScrollValue] = useState(false);
  const scrollV: number = 500;

  useEffect(() => {
    function handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > scrollV) {
        setScrollValue(true);
      } else if (window.scrollY < scrollV) {
        setScrollValue(false);
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener<"scroll">("scroll", handleScroll);
    };
  }, [scrollValue]);
  
  return (
    <div className={styles.maincontainer}>
      {!scrollValue ? <Navigation />  :  null}
      
      <PageOne />
      <PageTwo />
    </div>
  );
}
