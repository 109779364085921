import React, { useEffect, useRef } from "react";
import { VideoProps } from "../interface/interface";
import styles from "./Video.module.scss";
/**************************
 * VideoComponent
 *************************/
export default function VideoComponent({ url }: VideoProps) {
  const refvid = useRef<HTMLVideoElement>(null);

  const vid: Readonly<VideoProps> = {
    url: "",
    nodownload: "nondownload",
  };

  useEffect(() => {
    let isMounted = true;

    if (refvid.current && isMounted) {
      refvid.current.playbackRate = .8;
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={styles.videocontainer}>
      <video
        ref={refvid}
        controlsList={vid.nodownload}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
}
