interface PropsI {
  degress: number;
  minutes: number;
  seconds: number;
  degress1: number;
  minutes1: number;
  seconds1: number;
  by60: number;
  by3600: number;
}

export const dis: Readonly<PropsI> = {
  degress: 9,
  minutes: 56,
  seconds: 12.28,
  degress1: 85,
  minutes1: 39,
  seconds1: 56.59,
  by60: 60,
  by3600: 3600,
};
